* {
    --brainz-most-darkest: #0000008a;
    --brainz-darkest: #23272a;
    --brainz-darker: #2c2f33;
    --brainz-dark: #56585b;
    --brainz-darkLight: #ccc;
    --brainz-color-text: rgb(233, 237, 239);
    --brainz-color-text-secondary: #bbb;

    --brainz-color-text-table-and-tab-headers: var(--brainz-color-text);
    --brainz-color-table-content: var(--brainz-color-text);
    --brainz-color-app-bar-heading: var(--brainz-color-text-secondary);
    --brainz-color-app-bar-filter: var(--brainz-color-text-secondary);
    --brainz-color-app-bar-right-drawer-icons: var(--brainz-color-text-secondary);

    --brainz-color-yellow: #E9C46A;
    --brainz-color-orange: #F4A261;
    --brainz-color-red: #EB886F;
    --brainz-color-blue: #0087B8;
    --brainz-color-green: #00a884;
    --brainz-color-green-light: #2A9D8F;
    --brainz-color-purple: #6B5FAD;

    font-family: Segoe UI,Helvetica Neue,Helvetica,Lucida Grande,Arial,Ubuntu,Cantarell,Fira Sans,sans-serif;
    font-feature-settings: "kern";
    font-kerning: auto;
    font-optical-sizing: auto;
    font-stretch: 100%;
    font-style: normal;
    font-variant-alternates: normal;
    font-variant-caps: normal;
    font-variant-east-asian: normal;
    font-variant-ligatures: normal;
    font-variant-numeric: normal;
    font-variant-position: normal;
    font-variation-settings: normal;

    -ms-overflow-style: none;
}

div[role=tabpanel] {
    background: var(--brainz-darker);
}

::-webkit-scrollbar {
    /*display: none;*/
}
html,
body,
#root,
#app,
#app > div {
    height: 100%;
    overflow: hidden; /* Hide scrollbars */
    user-select: none;
}

.DialogContent {
    background: var(--brainz-darker);
    height: 100%;
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
}
.gap5 {
    gap: 5px;
}
.gap10 {
    gap: 10px;
}
.gap20 {
    gap: 20px;
}

.MuiListItem-root.gap10 > li:not(:last-child) {
    margin-bottom: 10px !important;
}

.filterItemPopup {
    left: 20px;
    top: 5px;
}

.CandidateInformationPanel {
    border-radius: 6px;
    padding: 10px;
    border: 2px solid var(--brainz-darkest);
    cursor: pointer;
}
.cursorPointer {
    cursor: pointer;
}
.CandidateInformationPanel:hover {
    border: 2px solid #56585b;
}

.MuiFormControl-root {
    margin-top: 5px !important;
}

/*.PaperRow > .MuiFormControl-root:not(:last-child) {
    margin-right: 10px !important;
}
.PaperRow > .MuiAutocomplete-root:not(:last-child) {
    margin-right: 10px !important;
}*/

.MuiInputBase-input.Mui-disabled {
    color: gray;
}

body {
    margin: 0px;
}

.App {
    width: 100%;
    height: 100%;
    background-color: var(--brainz-darkest);
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-float infinite 3s ease-in-out;
    }
}

.App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

.AppAccountMenuItem {
    min-height: 42px !important;
}

.AppAccountMenu {
    min-width: 200px !important;
    background-color: var(--brainz-darker) !important;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    border-radius: 4px !important;
    position: absolute !important;
    min-height: 16px !important;
    max-width: calc(100% - 32px) !important;
    outline: 0px !important;
    max-height: calc(100% - 96px) !important;
    overflow: visible !important;
    filter: drop-shadow(rgba(0, 0, 0, 0.37) 0px 1px 4px) !important;
    margin-top: 12px !important;
    box-shadow: none !important;
}
.AppAccountMenu::before {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    right: 14px;
    width: 10px;
    height: 10px;
    background-color: var(--brainz-darker);
    transform: translateY(-50%) rotate(45deg);
    z-index: 0;
}
.App-link {
    color: rgb(112, 76, 182);
}
.AppNavigation {
    margin-top: 65px !important;
    width: 57px;
    overflow: hidden;
}

.tableCell {
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px;
    flex-direction: column;
    font-size: 14px;
    color: var(--brainz-color-table-content);
}

.hoverContainer .hoverContent {
    display: none;
}
.hoverContainer:hover .hoverContent {
    display: block;
}
.hoverContainer:active .hoverContent {
    display: block;
}

.alignLeft {
    align-items: flex-start;
    justify-content: left;
    text-align: left;
}

.alignRight {
    align-items: flex-end;
    justify-content: right;
    text-align: right;
}

.alignCenter {
    text-align: center;
    align-items: center;
    justify-content: center;
}

.fixed {
    overflow: hidden;
    height: 100%;
}

/* Bugfix for wrong position of endAdornment Icon in Datepicker */
.MuiInputAdornment-root > .MuiButtonBase-root {
    padding-right: 5px !important;
}

/* Bugfix for fix end adornment position in autocomplete */
.MuiOutlinedInput-adornedEnd > .MuiInputAdornment-positionEnd {
    margin-left: 0px !important;
}

.inputFullWidth {
    min-width: 100px;
    color: var(--brainz-color-text-secondary);
    width: 100%;
}

.marginLeft5 {
    margin-left: 5px !important;
}
.marginRight5 {
    margin-right: 5px !important;
}
.margin5 {
    margin: 5px !important;
}
.marginTop5 {
    margin-top: 5px !important;
}
.marginTop10 {
    margin-top: 10px !important;
}
.paddingTop10 {
    padding-top: 10px !important;
}

.paddingBottom10 {
    padding-bottom: 10px;
}

.paddingLeft10 {
    padding-left: 10px;
}

.paddingRight5 {
    padding-right: 5px;
}

.padding5 {
    padding: 5px;
}

.padding10 {
    padding: 10px;
}

.test {
    background-color: rgb(241, 245, 249);
    border-bottom-color: rgb(226, 232, 240);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom-style: solid;
    border-bottom-width: 0px;
    border-left-color: rgb(226, 232, 240);
    border-left-style: solid;;
    border-left-width: 0px;
    border-right-color: rgb(226, 232, 240);
    border-right-style: solid;
    border-right-width: 0px;
    border-top-color: rgb(226, 232, 240);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-top-style: solid;
    border-top-width: 0px;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.1) 0px 4px 6px -4px;
    box-sizing: border-box;
    color: rgb(30, 41, 59);
    display: flex;
    flex-basis: auto;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    font-feature-settings: "salt";
    font-size: 14px;
    font-variation-settings: normal;
    line-height: 21px;
    max-width: 1440px;
    overflow-x: hidden;
    overflow-y: hidden;
    tab-size: 4;
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    width: 726px;
    -webkit-font-smoothing: auto;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.marginBottom5 {
    margin-bottom: 5px !important;
}


.hide {
    display: none !important;
}

.marginBottom10 {
    margin-bottom: 10px !important;
}

/**
FLEX
 */
.PaperRowFixedHeight {
    margin: 5px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-wrap: wrap;
    user-select: none;
    overflow: hidden;
}

.PaperRow {
    flex: 1;
    margin: 5px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-wrap: wrap;
    user-select: none;
    overflow: hidden;
}

.overflow {
    overflow: auto !important;
}

.flexWrap {
    flex-wrap: wrap;
}

.flex {
    flex: 1;
    display: flex;
    align-items: stretch;
    overflow: hidden;
    align-content: stretch;
}

.flexColumn {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow: hidden;
    align-content: stretch;
}

.Overlay {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(013, 130, 230, 0.5);
    border: 1px solid red;
    cursor: not-allowed;
}

.flexVerticalCentered {
    flex-wrap: wrap;
    vertical-align: middle;
    align-self: center;
}

.flexCenterOnlyChildren {
    flex-wrap: wrap;
    vertical-align: middle;
    align-items: center;
}

.alignSelfCenter {
    align-self: center;
}

.flexCentered {
    vertical-align: middle;
    align-items: center;
    justify-content: center;
}

.flexRight {
    justify-content: flex-end;
}

.flexAutoGrow {
    flex-shrink: 0;
    flex: unset !important;
}

.flexUnConfigured {
    display: flex;
}

@keyframes App-logo-float {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(0px);
    }
}

.profileItemContainer {
    padding:5px;
    cursor: pointer;
}

.profileItemContainer:hover {
    background-color: var(--brainz-most-darkest);
    border-radius: 5px;
}

.formLabel {
    font-weight: bold;
    padding-bottom: 3px;
}
.height100 {
    height: 100%;
}
.formLabelSmall {
    font-weight: 500;
    font-size: 14px;
    padding-bottom: 3px;
}
.formContent {
    font-size: 12px;
    padding-bottom: 3px;
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    align-items: start;
}
.ignoreColor {
    color: inherit;
}

a.formContent {
    color: var(--brainz-color-text-secondary);
}
.ellipseText {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.row {
    flex-direction: row;
    align-items: center;
}
.formRow {
    display: flex;
    margin: 16px;
}
.formRowColumn {
    display: flex;
    flex-direction: column;
    padding: 5px;
}

/*to avoid bug that comes up through logic in CustomFullScreenDialog*/
div.MuiAutocomplete-popper {
    z-index: 1601 !important;
}
div.MuiPopover-root {
    z-index: auto;
}
[role=tooltip] {
    /*z-index: 1500 !important;*/
}

.MuiAutocomplete-option[data-focus="true"] {
    background-color: var(--brainz-darker) !important;
}
.MuiListItem-button:hover {
    background-color: var(--brainz-darker) !important;
}

.MuiAutocomplete-option[aria-selected="true"] {
    background-color: var(--brainz-dark) !important;
}
.MuiListItem-button.Mui-selected {
    background-color: var(--brainz-dark) !important;
}

.MuiPickersCalendarHeader-dayLabel {
    color: white !important;
    font-weight: bold;
}
