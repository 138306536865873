.chip {
    color: var(--brainz-darker) !important;
    max-width: 100% !important;
    background-color: var(--brainz-color-text) !important;
}

.employeeChip1 {
    background-color: #000 !important;
}
.employeeChip2 {
    background-color: var(--brainz-color-blue) !important;
}
.employeeChip3 {
    background-color: #ff78cb !important;
}
.employeeChip4 {
    background-color: #61bd4f !important;
}
.employeeChip9 {
    background-color: var(--brainz-color-purple) !important;
}



