.root {
    width: 100%;
    font-size: .875rem;
    background: var(--brainz-darker);
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
    border-radius: 6px;
    flex-direction: column;
    cursor: pointer;
    margin-top: 5px;
    display: flex;
}
.rootUnread {
    border-left: 3px solid #006edc;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
    border-radius: 3px;
}
.rootUnassigned {
    border-left: 3px solid var(--brainz-color-orange);
}
.root:hover {
    background-color: #56585B;
}
.header {
    font-weight: bold;
    margin: 0 5px;
    padding: 5px;
    display: flex;
}
.headerTitle {
    flex: 1;
}
.headerDate {
    display: flex;
    width: 80px;
    text-align: right;
    justify-content: right;
    font-weight: normal;
    vertical-align: middle;
    align-items: center;
    height: 24px;
}
.innerRoot {
    color: var(--brainz-color-text-secondary);
    margin: 5px;
    padding: 5px;
    flex: 1;
}
