.root {
    background-color: var(--brainz-darkest);
    overflow: auto;
    flex: 1;
}

.expandContainer {
    display: flex;
    margin: 5px;
}

.expandLabel {
    padding-top: 2px;
}
