.subHeaderContainer {
    padding-left: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.subHeader {
    line-height: 40px !important;
}

.list {
    padding: 0px !important;
    overflow: auto;
    margin-left: -11px !important;
}

.hide {
    display: none !important;
}

.popper {
    background: var(--brainz-darker);
    margin-left: -100px;
    border: 1px solid var(--brainz-darkest);
}
