
.chip {
    color: var(--brainz-darker) !important;
    max-width: 100% !important;
    background-color: var(--brainz-color-orange) !important;
}
.chip:hover {
    background-color: #aa7143 !important;
}
.chip:focus {
    background-color: #aa7143 !important;
}

