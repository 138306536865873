
.badgeRed > * {
    background-color: var(--brainz-color-red) !important;
    color: var(--brainz-darker) !important;
}
.badgeOrange > * {
    background-color: var(--brainz-color-orange) !important;
    color: var(--brainz-darker) !important;
}
.badgeGreen > * {
    background-color: var(--brainz-color-green) !important;
}
.badgeGrey > * {
    background-color: #bdbdbd !important;
    color: var(--brainz-darker) !important;
}
