.root {
    cursor: text;
    width: 100%;
}

.dark:hover {
    background-color: var(--brainz-darker);
}

.light:hover {
    background-color: #56585B;
    color: var(--brainz-darkLight);
}
