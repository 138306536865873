.paper {
    margin: 5px;
    width: 420px;
    overflow: hidden;
}

.right {
    width: 100%;
    justify-content: right;
    flex: 1;
    display: flex;
    align-items: center;
    max-height: 45px;
}

.header {
    padding-bottom: 5px;
    padding-left: 10px;
}

.divider {
    margin-bottom: 5px;
}

.filterPanel {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    overflow: hidden;
    height: calc(100% - 59px);
    display: flex;
    flex-direction: column;
}
