.root {
    padding-right: 5px;
    padding-left: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
    border: 1px solid rgba(233, 237, 239, 0.2);
    max-width: 960px;
    min-height: 50px;
}

.input {
    margin-left: 5px;
    flex: 1;
    color: var(--brainz-color-app-bar-filter);
}

.iconButton {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.divider {
    height: 28px !important;
    width: 1px !important;
    margin: 4px;
    background-color: var(--brainz-darker) !important;
}

