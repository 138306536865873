.titleIcon {
    border-radius: 3px;
    margin: 5px 10px;
    width: 35px;
    display: flex;
    vertical-align: middle;
    align-items: center;
}

.titleText {
    font-size: 16px;
    font-weight: 600;
    min-height: 50px;
    display: flex;
    flex-wrap: wrap;
    vertical-align: middle;
    align-items: center;
}

.titleContainer {
    display: flex;
    border-radius: 6px 6px 0px 0px;
    background: var(--brainz-darkest);
    color: var(--brainz-color-text-secondary);
    max-height: 60px;
    padding-left: 10px;
}

.titleContainerLight {
    display: flex;
    border-radius: 6px 6px 0px 0px;
    background: var(--brainz-darker);
    color: var(--brainz-color-text-secondary);
    max-height: 60px;
    padding-left: 10px;
}

.titleButtons {
    flex: 1;
    padding-right: 15px;
    flex-wrap: wrap;
    vertical-align: middle;
    align-items: center;
}

.content {
    border-left: 4px solid var(--brainz-darkest);
    border-right: 4px solid var(--brainz-darkest);
    border-bottom: 4px solid var(--brainz-darkest);
    border-radius: 0px 0px 6px 6px;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
}

.contentLight {
    border-left: 4px solid var(--brainz-darker);
    border-right: 4px solid var(--brainz-darker);
    border-bottom: 4px solid var(--brainz-darker);
    border-radius: 0px 0px 6px 6px;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
}
