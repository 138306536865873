
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.5) var(--brainz-darkest);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 5px;
}

*::-webkit-scrollbar-track {
    background: var(--brainz-darker);
}

*::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 10px;
    border: 1px solid var(--brainz-darker);
    box-shadow: inset 0 0 0 20px #0000003d;
}
