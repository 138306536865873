
.chip {
    color: var(--brainz-darker) !important;
    max-width: 100% !important;
    background-color: var(--brainz-color-red) !important;
}
.chip:hover {
    background-color: rgb(from var(--brainz-color-red) r g b / 50%) !important;
}
.chip:focus {
    background-color: rgb(from var(--brainz-color-red) r g b / 50%) !important;
}
