.root {
    width: 100%;
    font-size: .875rem;
    background: var(--brainz-darker);
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
    border-radius: 6px;
    flex-direction: column;
    cursor: pointer;
    margin-top: 5px;
    margin-bottom: 10px;
}
.root:hover {
    background-color: #56585B;
}
.innerRoot {
    color: var(--brainz-color-text-secondary);
    margin: 0 15px;
}
.icon {
    width: 24px;
    height: 24px;
    float: left;
    padding: 5px;
    margin-top: -20px;
    margin-right: 15px;
    border-radius: 3px;
}
