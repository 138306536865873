.popper {
    background: var(--brainz-darker);
    border: 1px solid var(--brainz-darkest);
    margin-top: 150px !important;
    width: 500px;
    padding: 5px;
}

.dateRangeBadge {
    margin-left: 30px;
}
