.flex1 {
    flex: 1;
}

.flex2 {
    flex: 2;
}

.flex3 {
    flex: 3
}

/*ELEMENTE UNTEREINANDER ANREIHEN*/
.flexContainerColumn {
    display: flex;
    flex-direction: column;
    /*overflow: hidden;*/
}

/*ELEMENTE NEBENEINANDER ANREIHEN*/
.flexContainerRow {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    /*overflow: hidden;*/
}

/** VERTICAL CENTERING **/
.flexContainerColumn.alignVerticalCenter {
    justify-content: center;
}
.flexContainerRow.alignVerticalCenter {
    align-items: center;
}
.alignVerticalCenter:not(.flexContainerColumn), .alignVerticalCenter:not(.flexContainerRow)  {
    display: flex;
    align-items: center;
}

/** HORIZONTAL CENTERING **/
.flexContainerColumn.alignHorizontalCenter {
    text-align: center;
    align-self: stretch;
}

.alignHorizontalCenter:not(.flexContainerColumn), .flexContainerRow.alignHorizontalCenter {
    text-align: center;
    justify-content: center;
    align-self: stretch;
    margin: 0px auto;
    width: 50%;
}

/** HORIZONTAL RIGHT */
.alignHorizontalRight.flexContainerRow {
    justify-content: right;
    text-align: right;
}
.alignHorizontalRight.flexContainerRow:not(.alignVerticalCenter) {
    align-items: flex-start;
}
.alignHorizontalLeft.flexContainerRow:not(.alignVerticalCenter) {
    align-items: flex-start;
}

.alignHorizontalRight:not(.flexContainerColumn, .flexContainerRow) {
    margin-left: auto;
    margin-right: 0;
    text-align: right;
}

.alignHorizontalRight.flexContainerColumn {
    align-items: flex-end;
}
